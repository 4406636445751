import { useEffect, useRef, useState } from "react";
import { CandidateDetailsHeader } from "../../../Components/CandidateDetailsHeader/CandidateDetailsHeader";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import "./PersonalDetails.scss";
import arrowDown from "../../../Assets/SVG/dropDownArrow.svg";
import UploadIcon from "../../../Assets/SVG/upload.svg";
import crossFile from "../../../Assets/SVG/crossFile.svg";
import {
  getAllCountries,
  getAllTimeZones,
  getCities,
  getStates,
} from "../../../Common/getCountries";
// import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import exclamation from "../../../Assets/SVG/exclamation-red.svg";
// import Popup from "../../../Components/Common/popUp/popUp";
// import close from "../../../Assets/SVG/Close.svg";
import {
  getDateAfterDays,
  getTodayDateAsMinDate,
} from "../../../Common/minDate";
import { fileUploadErrors } from "../../../mockData/fileUploadErrors";
import { FilesError } from "../../../Components/Common/FileUploadError/FileUploadError";
import info_icon from "../../../Assets/SVG/personalDetails/i_icon.svg";
import blue_down_arrow from "../../../Assets/SVG/personalDetails/blue_round_arrow.svg";
import moment from "moment-timezone";

const initFormData = {
  id: "",
  firstName: "",
  lastName: "",
  country: "",
  timeZone: "",
  state: "",
  city: "",
  pincode: "",
  education: "",
  qualification: "",
  experienceInYears: "",
  availabilityForWork: "",
  joiningDate: "",
  lastWorkingDate: "",
  noticePeriod: "",
  linkedInProfile: "",
  stackOverflowLink: "",
  githubLink: "",
  countryCode: "+91",
  mobile: "",
};

const initErrorFormData = {
  firstName: false,
  lastName: false,
  country: false,
  timeZone: false,
  state: false,
  city: false,
  pincode: false,
  education: false,
  qualification: false,
  experienceInYears: false,
  availabilityForWork: false,
  joiningDate: false,
  lastWorkingDate: false,
  noticePeriod: false,
  countryCode: false,
  mobile: false,
  linkedInProfile: false,
};

export const PersonalDetails = () => {
  return (
    <>
      <CandidateHeader />
      <CandidateDetailsHeader indexActive={0} />
      <div className="personal-details">
        <div className="title">
          Please complete your profile. This will help us to customize the tests
          in order to identify your strongest skills and match you with the
          suitable projects.
        </div>
        <DevPersonalDetails />
      </div>
    </>
  );
};

const DevPersonalDetails = () => {
  const navigate = useNavigate();
  const [selectedResume, setSelectedResume] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [resumeName, setResumeName] = useState("");
  const [certificatesNames, setCertificatesNames] = useState([]);
  const [countryCode, setCountryCode] = useState([
    // "+91", // India
    // "+1", // United States
    // // "+1", // Canada
    // "+234", // Nigeria
    // "+20", // Egypt
    // "+27", // South Africa
    // "+254", // Kenya
    // "+212", // Morocco
    // "+233", // Ghana
    // "+251", // Ethiopia
  ]);

  const [experience, setExperience] = useState([
    "0-1 years",
    "1-3 years",
    "3-5 years",
    "5-7 years",
    "7-10 years",
    "10-15 years",
    "15-20 years",
    "20-25 years",
    "25+ years",
  ]);
  const [availability, setAvailability] = useState([
    "Immediate",
    "Serving notice period",
    "Currently working",
  ]);
  const [educationIndex, setEducationIndex] = useState("");
  const [education, setEducation] = useState([]);
  const [formData, setFormData] = useState({ ...initFormData });
  const [updatedFormData, setUpdatedFormData] = useState({});
  const [updateForm, setUpdateForm] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [formError, setFormError] = useState({ ...initErrorFormData });

  const [viewLoader, setViewLoader] = useState(false);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [isLinkedValid, setIsLinkedValid] = useState(true);
  const [viewPersonalDetails, setViewPersonalDetails] = useState(true);
  const [viewProfessional, setViewProfessional] = useState(true);
  const [shouldRender, setShouldRender] = useState(viewPersonalDetails);
  const [shouldRender2, setShouldRender2] = useState(viewProfessional);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const handleFormChange = (e) => {
    // console.log(e.target.name);
    let { name, value } = e.target;
    // return;

    if (name === "linkedInProfile") {
      setIsLinkedValid(true);
    }

    if (name === "country") {
      getStateList(value);
      getCountryEducation(value);
    }
    if (name === "state") {
      getCitiesList(value);
    }

    if (name === "noticePeriod") {
      let newArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      if (value.length > 2) return;
      let newVal = value.split("");

      for (let val of newVal) {
        if (!newArr.includes(parseInt(val))) {
          return;
        } else {
          // console.log("found==>", newArr.indexOf(parseInt(val)));
        }
      }
    }

    if (name === "mobile") {
      let newArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      if (value.length > 10) return;
      let newVal = value.split("");

      for (let val of newVal) {
        if (!newArr.includes(parseInt(val))) {
          return;
        } else {
          // console.log("found==>", newArr.indexOf(parseInt(val)));
        }
      }
    }

    if (name === "pincode") {
      // Convert value to uppercase
      value = value.toUpperCase();

      if (/^[0-9A-Z\-\/\s]{0,8}$/.test(value)) {
        // The value is now in uppercase and is a valid string with a maximum length of 8 characters, including alphanumeric and selected special characters (-, /, space)
        // You can add your logic here for the valid case
      } else {
        // Invalid value, return or handle the error
        return;
      }
    }

    setFormError((prev) => ({
      ...prev,
      [name]: false,
    }));

    if (activeField === name) {
      setActiveField(null);
    }

    if (name === "education") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        qualification: "",
      }));
    } else if (name === "availabilityForWork") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        joiningDate: "",
        lastWorkingDate: "",
        noticePeriod: "",
      }));
    } else if (name === "firstName" || name === "lastName") {
      const inputText = value;
      const alphabeticOnly = inputText.replace(/[^a-zA-Z]/g, ""); // Remove non-alphabetic characters
      setFormData((prevData) => ({
        ...prevData,
        [name]: alphabeticOnly,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (updateForm) {
      setUpdatedFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleFormSubmit = async (e) => {
    // setViewLoader(true);
    e.preventDefault();
    // console.log("submit", formData);
    if (
      checkFormData(
        formData,
        setFormError,
        selectedResume,
        certificates,
        setIsLinkedValid
      )
    ) {
      setViewLoader(true);
      // console.log("success");
      const endpoint = updateForm
        ? "/candidate/details/update/personal"
        : "/candidate/details/save/personal";
      const data = generateFormData(
        updateForm ? updatedFormData : formData,
        selectedResume,
        certificates
      );

      // console.log(data);
      // return;

      try {
        const res = await axios
          .post(endpoint, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => response.data);
        if (res) {
          // navigate("/details/professional");
          navigate("/assessment/home");
        }
      } catch (err) {
        console.log("handleFormSubmit error==>", err);
      } finally {
        setViewLoader(false);
      }
    } else {
      const ele = document.getElementById("details-form");
      ele.scrollIntoView();
      setViewPersonalDetails(true);
      setViewProfessional(true);
    }
  };

  // const tempCountries = [
  //   "India",
  //   "United States",
  //   "Canada",
  //   "Nigeria",
  //   "Egypt",
  //   "South Africa",
  //   "Kenya",
  //   "Morocco",
  //   "Ghana",
  //   "Ethiopia",
  // ];

  const getCountryList = async () => {
    try {
      const res = await getAllCountries();
      // console.log("all countries", res);
      const countries = res.map((item) => item.name);
      const dialCode = res.map((item) => item.dialCode);
      setCountries([...countries]);
      setCountryCode([...dialCode]);
      // setCountries([...tempCountries]);
    } catch (err) {
      setCountries([]);
      console.log("error in country fetching==>", err);
    }
  };

  const getStateList = async (value, setNull = true) => {
    if (setNull) {
      const data = {
        target: {
          value: "",
          name: "state",
        },
      };
      handleFormChange(data);
    }
    try {
      // setViewLoader(true);
      const data = {
        countryName: value,
      };
      const res = await getStates(data);
      setStates([...res]);
    } catch (err) {
      setStates([]);
      console.log("error in states fetching==>", err);
    } finally {
      setViewLoader(false);
    }
  };

  const getCitiesList = async (value, setNull = true, country = "") => {
    if (setNull) {
      const data = {
        target: {
          value: "",
          name: "city",
        },
      };
      handleFormChange(data);
    }
    try {
      // setViewLoader(true);
      const data = {
        countryName: formData?.country ? formData?.country : country,
        stateName: value,
      };
      const res = await getCities(data);
      setCities([...res]);
    } catch (err) {
      setCities([]);

      console.log("error in states fetching==>", err);
    } finally {
      setViewLoader(false);
    }
  };

  const preFillPersonalDetails = (data) => {
    const joining = data?.joiningDate?.split("T")[0];
    const lwd = data?.lastWorkingDate?.split("T")[0];
    const countryCodeData = data?.countryCode
      ? data?.countryCode
      : formData?.countryCode;

    // Combine the timeZone and utcTimeDifference
    const timeZone = data?.timeZone;
    const formattedTimeZone = timeZone
      ? `${timeZone.timeZone} (${timeZone.utcTimeDifference})`
      : null;

    const temp = {
      id: data?.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      country: data?.country,
      state: data?.state,
      city: data?.city,
      pincode: data?.pincode,
      education: data?.education,
      qualification: data?.qualification,
      experienceInYears: data?.experienceInYears,
      availabilityForWork: data?.availabilityForWork,
      joiningDate: joining,
      lastWorkingDate: lwd,
      noticePeriod: data?.noticePeriod,
      linkedInProfile: data?.linkedInProfile,
      stackOverflowLink: data?.stackOverflowLink,
      githubLink: data?.githubLink,
      countryCode: countryCodeData,
      mobile: data?.mobile,
      timeZone: formattedTimeZone, // Appending the combined timeZone here
    };

    getCountryEducation(data.country, data.education);
    setFormData({ ...temp });
    getStateList(data?.country, false);
    getCitiesList(data?.state, false, data?.country);

    const index = education?.findIndex((edu) => edu?.title === data?.education);
    setEducationIndex(index);

    if (data?.firstName) {
      setUpdateForm(true);
    }
  };

  const getCandidatePersonalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/personal";
      const data = {
        // id: sessionStorage.getItem("email"),
        id: localStorage.getItem("email"),
        clientId: localStorage.getItem("clientId"),
      };
      const res = await axios.post(endpoint, data);
      if (res?.data !== "") {
        // console.log("success in getCandidatePersonalDetails", res);
        preFillPersonalDetails(res?.data);
        if (res?.data?.resumeName) setResumeName(res?.data?.resumeName);
        if (res?.data?.certificationName)
          setCertificatesNames([...res?.data?.certificationName]);
        // console.log("==>", res?.data?.certificationName);
      }
    } catch (err) {
      console.log("error in getCandidatePersonalDetails", err);
    }
  };

  const handleInputFocus = (e) => {
    const { name } = e.target;
    if (formData[name] === "" || formData[name] === null) setActiveField(name);
    else {
      setActiveField(null);
    }
  };

  const convertEducationData = (originalData, educationSelected) => {
    const tempEducationData = [
      {
        title: "High School",
        options: ["10th", "12th"],
      },
      {
        title: "Under graduation",
        options: ["Bsc", "Btech", "Bcom"],
      },
      {
        title: "Graduate",
        options: ["Msc", "Mtech", "MCA", "Mcom"],
      },
      {
        title: "Doctorate",
        options: ["AI", "Maths", "ML"],
      },
      {
        title: "Others",
      },
    ];

    const allEducationData = originalData.map((level) => {
      const { name, qualification, i_icon, i_description } = level;

      const formattedLevel = {
        title: name,
      };

      if (qualification && qualification.length > 0) {
        formattedLevel.options = qualification.map((qual) => qual.name);
      }
      if (i_icon) {
        formattedLevel.i_icon = i_icon;
      }
      if (i_description) {
        formattedLevel.i_description = i_description;
      }
      return formattedLevel;
    });
    // console.log(allEducationData);
    if (allEducationData?.length > 0) {
      setEducation([...allEducationData]);
    } else {
      setEducation([...tempEducationData]);
    }

    const matchItem = educationSelected
      ? educationSelected
      : formData?.education;

    let index = allEducationData?.findIndex((edu) => edu?.title === matchItem);
    if (index === -1) {
      index = tempEducationData?.length - 1;
    }
    // console.log("formnm data=>", formData);
    // console.log("education index=>", index);
    setEducationIndex(index);
  };

  const getCountryEducation = async (country, education = "") => {
    try {
      // setViewLoader(true);
      const endpoint = "/candidate/details/get/education";
      const data = {
        email: localStorage.getItem("email"),
        countryName: country,
        clientId: localStorage.getItem("clientId"),
      };
      const res = await axios.post(endpoint, data);
      if (res?.data) {
        convertEducationData(res?.data?.education, education);
      }
    } catch (err) {
      console.error("getCountryEducation error==>", err);
    } finally {
      setViewLoader(false);
    }
  };

  const getFormattedTimeZones = () => {
    // Get all time zones from moment-timezone
    const timeZones = moment.tz.names();

    // Create a set to store unique time zone strings
    const uniqueTimeZones = new Set();

    timeZones.forEach((zone) => {
      // Get the current time in the timezone
      const format = moment.tz(zone).format("Z"); // e.g., "+05:30"

      // Format the string as `Asia/Kolkata GMT+5:30`
      const formattedTimeZone = `${zone} GMT${format}`;

      // Add to the set
      uniqueTimeZones.add(formattedTimeZone);
    });

    // Convert the Set to an array and return
    return [...uniqueTimeZones];
  };

  const handleSetTimeZones = async () => {
    try {
      // ---------- only limited are coming from backend---------
      // const res = await getAllTimeZones();
      // if (res && Array.isArray(res.timeZones)) {
      //   const uniqueTimeZones = new Set(
      //     res.timeZones.map((val) => `${val?.timeZone} ${val?.utcFormat}`)
      //   );
      //   // Convert Set to array and update the state
      //   setTimezoneOptions([...uniqueTimeZones]);
      // }
      const res = getFormattedTimeZones();
      setTimezoneOptions([...res]);
    } catch (err) {
      console.log("error in getAllTimeZoneList=>", err);
    }
  };

  useEffect(() => {
    if (viewPersonalDetails) setShouldRender(true);
  }, [viewPersonalDetails]);

  const onAnimationEnd = () => {
    if (!viewPersonalDetails) setShouldRender(false);
  };

  useEffect(() => {
    if (viewProfessional) setShouldRender2(true);
  }, [viewProfessional]);

  const onAnimationEnd2 = () => {
    if (!viewProfessional) setShouldRender2(false);
  };

  useEffect(() => {
    let timeout;

    if (activeField) {
      timeout = setTimeout(() => {
        setActiveField(null);
      }, 50000);
    }

    return () => clearTimeout(timeout);
  }, [activeField]);

  const getAllNecessaryData = async () => {
    setViewLoader(true);
    await handleSetTimeZones();
    await getCountryList();
    await getCandidatePersonalDetails();
    setViewLoader(false);
  };

  useEffect(() => {
    getAllNecessaryData();
    setMinDate(getTodayDateAsMinDate());
    setMaxDate(getDateAfterDays(90));
  }, []);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      <div className="dev-details" id="details-form">
        <form onSubmit={handleFormSubmit}>
          <div className="items-container">
            <div
              className="item-container-title"
              onClick={() => setViewPersonalDetails(!viewPersonalDetails)}
            >
              <div>Personal Details</div>
              <div
                style={{
                  transform: `rotate(${
                    viewPersonalDetails ? "0deg" : "180deg"
                  })`,
                }}
                className="arrow-container"
              >
                <img src={blue_down_arrow} alt="" />
              </div>
            </div>
            {/* {viewPersonalDetails && ( */}
            {shouldRender && (
              <div
                className={viewPersonalDetails ? "fade-in" : "fade-out"}
                onAnimationEnd={onAnimationEnd}
              >
                <div className="item-content">
                  <div className="field1">
                    <div className="field2">
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="First name"
                        className={formError?.firstName ? "errorInput" : ""}
                        name="firstName"
                        value={formData?.firstName}
                        onChange={(e) => handleFormChange(e)}
                        onFocus={handleInputFocus}
                      />
                      {formError?.firstName ? (
                        <div className="missing">
                          <div>
                            <img src={exclamation} alt="" />
                          </div>
                          <div className="mandatory">First Name is missing</div>
                        </div>
                      ) : (
                        activeField === "firstName" && (
                          <div className="mandatory">
                            This field is mandatory!
                          </div>
                        )
                      )}
                    </div>
                    <div className="field2">
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        className={formError?.lastName ? "errorInput" : ""}
                        value={formData?.lastName}
                        onChange={(e) => handleFormChange(e)}
                        onFocus={handleInputFocus}
                      />

                      {formError?.lastName ? (
                        <div className="missing">
                          <div>
                            <img src={exclamation} alt="" />
                          </div>
                          <div className="mandatory">Last Name is missing</div>
                        </div>
                      ) : (
                        activeField === "lastName" && (
                          <div className="mandatory">
                            This field is mandatory!
                          </div>
                        )
                      )}
                    </div>
                    <div className="field2">
                      <label>Contact Number</label>
                      <div className="field3">
                        {/* <DropDownBox
                          options={countryCode}
                          value={formData.countryCode}
                          name={"countryCode"}
                          handleChange={handleFormChange}
                          handleInputFocus={handleInputFocus}
                          activeField={activeField}
                          formError={formError}
                          setFormError={setFormError}
                        /> */}
                        <DropDownBoxSearch
                          placeholder=""
                          options={countryCode}
                          name="countryCode"
                          value={formData.countryCode}
                          handleChange={handleFormChange}
                          handleInputFocus={handleInputFocus}
                          activeField={activeField}
                          formError={formError}
                        />
                        <div>
                          <input
                            type="text"
                            placeholder="contact"
                            className={formError?.mobile ? "errorInput" : ""}
                            value={formData.mobile}
                            name="mobile"
                            maxLength="10"
                            onChange={(e) => handleFormChange(e)}
                            onFocus={handleInputFocus}
                          />
                          {}
                          {formError?.mobile ? (
                            <div className="missing">
                              <div>
                                <img src={exclamation} alt="" />
                              </div>
                              <div className="mandatory">
                                {formData.mobile.length > 0 &&
                                formData.mobile.length < 10
                                  ? "Contact Number must be 10 digits"
                                  : "Contact Number is missing"}
                              </div>
                            </div>
                          ) : (
                            activeField === "mobile" && (
                              <div className="mandatory">
                                This field is mandatory!
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field1">
                    <div className="field2">
                      <label>Country or Region</label>
                      <DropDownBoxSearch
                        placeholder="Country"
                        options={countries}
                        name="country"
                        value={formData.country}
                        handleChange={handleFormChange}
                        handleInputFocus={handleInputFocus}
                        activeField={activeField}
                        formError={formError}
                      />
                    </div>
                    <div className="field2">
                      <label>Time Zone</label>
                      <DropDownBoxSearch
                        placeholder="Time Zone"
                        options={timezoneOptions}
                        name="timeZone"
                        value={formData.timeZone}
                        handleChange={handleFormChange}
                        handleInputFocus={handleInputFocus}
                        activeField={activeField}
                        formError={formError}
                      />
                    </div>
                    <div className="field2">
                      <label>Zip Code / Pin Code</label>
                      <input
                        type="tel"
                        placeholder="Enter the zip code"
                        name="pincode"
                        className={formError?.pincode ? "errorInput" : ""}
                        value={formData.pincode}
                        onChange={(e) => handleFormChange(e)}
                        onFocus={handleInputFocus}
                      />

                      {formError?.pincode ? (
                        <div className="missing">
                          <div>
                            <img src={exclamation} alt="" />
                          </div>
                          <div className="mandatory">Zip Code is missing</div>
                        </div>
                      ) : (
                        activeField === "pincode" && (
                          <div className="mandatory">
                            This field is mandatory!
                          </div>
                        )
                      )}
                    </div>
                    <div className="field2">
                      <label>State</label>
                      <DropDownBoxSearch
                        options={states}
                        placeholder={"State"}
                        name="state"
                        value={formData.state}
                        handleChange={handleFormChange}
                        handleInputFocus={handleInputFocus}
                        activeField={activeField}
                        formError={formError}
                      />
                    </div>
                    <div className="field2">
                      <label>City</label>
                      <DropDownBoxSearch
                        options={cities}
                        placeholder={"City"}
                        value={formData.city}
                        name="city"
                        handleChange={handleFormChange}
                        handleInputFocus={handleInputFocus}
                        activeField={activeField}
                        formError={formError}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="items-container">
            <div
              className="item-container-title"
              onClick={() => setViewProfessional(!viewProfessional)}
            >
              <div>Professional Details</div>
              <div
                style={{
                  transform: `rotate(${viewProfessional ? "0deg" : "180deg"})`,
                }}
                className="arrow-container"
              >
                <img src={blue_down_arrow} alt="" />
              </div>
            </div>
            {shouldRender2 && (
              <div
                className={viewProfessional ? "fade-in" : "fade-out"}
                onAnimationEnd={onAnimationEnd2}
              >
                <div className="item-content">
                  {education?.length > 0 && (
                    <>
                      <div className="fieldRadio">
                        <label>Highest Education Qualification</label>
                        <div>
                          {" "}
                          {education?.map((ele, index) => (
                            <div key={index}>
                              {
                                <input
                                  type="radio"
                                  onChange={(e) => {
                                    setEducationIndex(index);
                                    handleFormChange(e);
                                  }}
                                  className={
                                    formError?.education ? "errorInput" : ""
                                  }
                                  value={ele?.title}
                                  name="education"
                                  checked={ele?.title === formData.education}
                                />
                              }{" "}
                              <span>{ele.title}</span>
                              {ele?.i_icon && (
                                <div className="info-icon">
                                  <div>
                                    <img src={info_icon} alt="" />
                                  </div>
                                  {ele?.i_description && (
                                    <div className="tooltip-content">
                                      <div>{ele?.i_description}</div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        {formError?.education && (
                          <div className="missing">
                            <div>
                              <img src={exclamation} alt="" />
                            </div>
                            <div className="mandatory">
                              Education is missing
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="field1">
                        <div className="field2">
                          {educationIndex !== "" && (
                            <>
                              {education[educationIndex]?.options ? (
                                <DropDownBox
                                  options={education[educationIndex]?.options}
                                  placeholder={"Enter your qualification"}
                                  value={formData.qualification}
                                  name="qualification"
                                  handleChange={handleFormChange}
                                  handleInputFocus={handleInputFocus}
                                  activeField={activeField}
                                  formError={formError}
                                />
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    name="qualification"
                                    className={
                                      formError?.qualification
                                        ? "errorInput"
                                        : ""
                                    }
                                    placeholder="Education"
                                    value={formData.qualification}
                                    onChange={(e) => handleFormChange(e)}
                                    onFocus={handleInputFocus}
                                  />
                                  {formError?.qualification ? (
                                    <div className="missing">
                                      <div>
                                        <img src={exclamation} alt="" />
                                      </div>
                                      <div className="mandatory">
                                        Qualification is missing
                                      </div>
                                    </div>
                                  ) : (
                                    activeField === "qualification" && (
                                      <div className="mandatory">
                                        This field is mandatory!
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="field1">
                    <div className="field2">
                      <label>Total Work Experience</label>
                      <DropDownBox
                        options={experience}
                        placeholder="Enter your experience"
                        value={formData.experienceInYears}
                        name="experienceInYears"
                        handleChange={handleFormChange}
                        handleInputFocus={handleInputFocus}
                        activeField={activeField}
                        formError={formError}
                      />
                    </div>
                    <div className="field2">
                      <label>Availability</label>
                      <DropDownBox
                        options={availability}
                        placeholder={"Availability"}
                        value={formData.availabilityForWork}
                        name="availabilityForWork"
                        handleChange={handleFormChange}
                        handleInputFocus={handleInputFocus}
                        activeField={activeField}
                        formError={formError}
                      />
                    </div>
                    {formData.availabilityForWork !== "" && (
                      <div className="field2">
                        <label>
                          {formData.availabilityForWork === availability[0]
                            ? "Available Date "
                            : formData.availabilityForWork === availability[1]
                            ? "Last Working Date"
                            : "Notice Period (In Days)"}
                        </label>
                        {formData.availabilityForWork !== availability[2] ? (
                          <>
                            {" "}
                            <input
                              type="date"
                              style={{
                                color:
                                  formData.joiningDate ||
                                  formData.lastWorkingDate
                                    ? ""
                                    : "rgb(167 167 175)",
                                fontWeight:
                                  formData.joiningDate ||
                                  formData.lastWorkingDate
                                    ? ""
                                    : "400",
                              }}
                              min={minDate}
                              max={maxDate}
                              value={
                                formData.availabilityForWork === availability[0]
                                  ? formData.joiningDate
                                  : formData.lastWorkingDate
                              }
                              name={
                                formData.availabilityForWork === availability[0]
                                  ? "joiningDate"
                                  : "lastWorkingDate"
                              }
                              className={
                                formError?.joiningDate ||
                                formError.lastWorkingDate
                                  ? "errorInput"
                                  : "date-empty"
                              }
                              // min={Date.now()}
                              onChange={(e) => handleFormChange(e)}
                              onFocus={handleInputFocus}
                            />
                            {formError?.joiningDate ? (
                              <div className="missing">
                                <div>
                                  <img src={exclamation} alt="" />
                                </div>
                                {/* <div className="mandatory">Joining date is missing</div> */}
                                <div className="mandatory">Date is missing</div>
                              </div>
                            ) : formError?.lastWorkingDate ? (
                              <div className="missing">
                                <div>
                                  <img src={exclamation} alt="" />
                                </div>
                                <div className="mandatory">
                                  {/* Last working date is missing */}
                                  Date is missing
                                </div>
                              </div>
                            ) : (
                              (activeField === "joiningDate" ||
                                activeField === "lastWorkingDate") && (
                                <div className="mandatory">
                                  This field is mandatory!
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <input
                              type="text"
                              placeholder="30"
                              value={formData.noticePeriod}
                              name="noticePeriod"
                              className={
                                formError?.noticePeriod ? "errorInput" : ""
                              }
                              onChange={(e) => handleFormChange(e)}
                              onFocus={handleInputFocus}
                            />
                            {formError?.noticePeriod ? (
                              <div className="missing">
                                <div>
                                  <img src={exclamation} alt="" />
                                </div>
                                <div className="mandatory">
                                  Notice Period is missing
                                </div>
                              </div>
                            ) : (
                              activeField === "noticePeriod" && (
                                <div className="mandatory">
                                  This field is mandatory!
                                </div>
                              )
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Linkedin Profile Stack Overflow (Optional) Github link (Optional) */}
                  <div className="field1">
                    <div className="field2">
                      <label>LinkedIn Profile </label>
                      <input
                        type="text"
                        placeholder="LinkedIn Profile"
                        value={formData.linkedInProfile}
                        name="linkedInProfile"
                        onChange={(e) => handleFormChange(e)}
                        className={
                          formError?.linkedInProfile || !isLinkedValid
                            ? "errorInput"
                            : ""
                        }
                        onFocus={handleInputFocus}
                      />
                      {!isLinkedValid ? (
                        <div className="missing">
                          <div>
                            <img src={exclamation} alt="" />
                          </div>
                          <div className="mandatory">
                            Please enter a valid url
                          </div>
                        </div>
                      ) : null}
                      {formError?.linkedInProfile ? (
                        <div className="missing">
                          <div>
                            <img src={exclamation} alt="" />
                          </div>
                          <div className="mandatory">
                            Linkedin Profile is missing
                          </div>
                        </div>
                      ) : (
                        activeField === "linkedInProfile" && (
                          <div className="mandatory">
                            This field is mandatory!
                          </div>
                        )
                      )}
                    </div>
                    <div className="field2">
                      <label>Stack Overflow (Optional) </label>
                      <input
                        type="text"
                        placeholder="Stack Overflow Profile"
                        value={formData.stackOverflowLink}
                        name="stackOverflowLink"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </div>
                    <div className="field2">
                      <label>Github Link (Optional)</label>
                      <input
                        type="text"
                        placeholder="Github Link Profile"
                        value={formData.githubLink}
                        name="githubLink"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </div>
                  </div>
                  <div className="files">
                    <ResumeUpload
                      selectedFile={selectedResume}
                      setSelectedFile={setSelectedResume}
                      resumeName={resumeName}
                    />
                    <CertificateUpload
                      selectedFiles={certificates}
                      setSelectedFiles={setCertificates}
                      certificatesName={certificatesNames}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="nextButton">
            <button>Next Step</button>
          </div>
        </form>
      </div>
    </>
  );
};

const DropDownBoxSearch = ({
  placeholder,
  options,
  value,
  name,
  handleChange,
  handleInputFocus,
  activeField,
  formError,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const [dropDownOptions, setDropdownOptions] = useState([...options]);
  const [itemValues, setItemValues] = useState(value);
  const componentRef = useRef(null);
  const [isInside, setIsInside] = useState(true);

  const dropDownChange = (item) => {
    // console.log("item clicked", item);
    setViewOptions(false);
    let data = {
      target: {
        name,
        value: item,
      },
    };
    handleChange(data);
  };

  const handleDivFocus = (item) => {
    let data = {
      target: {
        name,
      },
    };
    handleInputFocus(data);
  };

  const handleDropDownOptions = (e = "") => {
    setItemValues(e);
    if (e?.trim() === "") {
      // If e is empty, show all values
      setDropdownOptions([...options]);
    } else {
      // If e is not empty, filter options based on the provided string
      const filteredOptions = options.filter((option) =>
        option?.toLowerCase()?.includes(e?.toLowerCase())
      );
      // return filteredOptions;
      setDropdownOptions([...filteredOptions]);
    }
  };

  const getUiWords = {
    availabilityForWork: "Availability",
    experienceInYears: "Experience",
    country: "Country",
    state: "State",
    city: "City",
    joiningDate: "Joining Date",
    lastWorkingDate: "Last Working Date",
  };

  // const handleOptionsBlur = (e) => {
  //   // console.log("blur item=>", e);

  //   // Delay the execution of the blur logic
  //   setTimeout(() => {
  //     // Your existing onBlur logic here
  //     if (itemValues !== value && callFunction) {
  //       const newVal = options.find((option) => option === e) || "";
  //       // console.log("blur item found=>", newVal);
  //       dropDownChange(newVal);
  //     } else {
  //       setViewOptions(false);
  //     }
  //   }, 250); // Adjust the delay time as needed
  // };

  useEffect(() => {
    handleDropDownOptions(value);
  }, [options]);

  useEffect(() => {
    setItemValues(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // Click outside the component
        // setIsInside(false);
        setIsInside(false);
      } else {
        // Click inside the component
        // setIsInside(true);
        setIsInside(true);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Your existing onBlur logic here
    // console.log("new click changes 1==>", itemValues);
    // console.log("new click changes 2==>", value);
    if (itemValues !== value) {
      const newVal = options.find((option) => option === itemValues) || "";
      console.log("blur item found=>", newVal);
      setItemValues(newVal);
      dropDownChange(newVal);
    } else {
      setViewOptions(false);
    }
  }, [isInside]);

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div
        tabIndex={0}
        className={`placeholder ${formError?.[name] ? "errorClass" : ""}`}
        onClick={() => setViewOptions(!viewOptions)}
        onFocus={() => handleDivFocus(name)}
      >
        {/* <div className={value === "" ? "" : "value"}>
          {value === "" || value === null ? placeholder : value}
        </div> */}
        <div className="value">
          <input
            type="text"
            placeholder={placeholder}
            value={itemValues}
            // onBlur={(e) => handleOptionsBlur(e.target.value)}
            onChange={(e) => handleDropDownOptions(e.target.value)}
          />
        </div>
        <div className="arrow">
          <img
            src={arrowDown}
            alt=""
            style={{ rotate: viewOptions ? "180deg" : "0deg" }}
          />
        </div>
      </div>
      {}
      {formError?.[name] ? (
        <div className="missing">
          <div>
            <img src={exclamation} alt="" />
          </div>
          <div className="mandatory2">
            {getUiWords[name] ? getUiWords[name] : name} is missing
          </div>
        </div>
      ) : (
        activeField === name && (
          <div className="mandatory">This field is mandatory! </div>
        )
      )}
      {viewOptions && (
        <div className="options-wrapper">
          {dropDownOptions?.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                dropDownChange(item);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const DropDownBox = ({
  placeholder,
  options,
  value,
  name,
  handleChange,
  handleInputFocus,
  activeField,
  formError,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const componentRef = useRef(null);

  const dropDownChange = (item) => {
    setViewOptions(false);
    let data = {
      target: {
        name,
        value: item,
      },
    };
    handleChange(data);
  };

  const handleDivFocus = (item) => {
    let data = {
      target: {
        name,
      },
    };
    handleInputFocus(data);
  };

  const getUiWords = {
    availabilityForWork: "Availability",
    experienceInYears: "Experience",
    country: "Country",
    state: "State",
    city: "City",
    joiningDate: "Joining Date",
    lastWorkingDate: "Last Working Date",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // Click outside the component
        setViewOptions(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div
        tabIndex={0}
        className={`placeholder ${formError?.[name] ? "errorClass" : ""}`}
        onClick={() => setViewOptions(!viewOptions)}
        onFocus={() => handleDivFocus(name)}
      >
        <div className={value === "" ? "no-value" : "value"}>
          {value === "" || value === null ? placeholder : value}
        </div>
        <div style={{ paddingTop: "5px" }}>
          <img
            src={arrowDown}
            alt=""
            style={{ rotate: viewOptions ? "180deg" : "0deg" }}
          />
        </div>
      </div>
      {}
      {formError?.[name] ? (
        <div className="missing">
          <div>
            <img src={exclamation} alt="" />
          </div>
          <div className="mandatory2">
            {getUiWords[name] ? getUiWords[name] : name} is missing
          </div>
        </div>
      ) : (
        activeField === name && (
          <div className="mandatory">This field is mandatory! </div>
        )
      )}
      {viewOptions && (
        <div className="options-wrapper">
          {options?.map((item, index) => (
            <div key={index} onClick={() => dropDownChange(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ResumeUpload = ({ selectedFile, setSelectedFile, resumeName }) => {
  /**
   * The function `handleFileChange` checks if a selected file exists, is not empty, and is within a
   * certain size limit, and then sets the selected file.
   * @returns The function `handleFileChange` returns `undefined` in both the cases where `file` is
   * `null` or when the file size is either 0 or greater than 10 * 1024 * 1024.
   */

  const [viewError, setViewError] = useState(false);
  const [fileError, setFileFileError] = useState({});

  const fileCheck = (file, fileType) => {
    if (fileType !== "pdf") {
      setFileFileError({ ...fileUploadErrors?.fileFormat });
      setViewError(true);
      return;
    }
    if (file && file.size === 0) {
      setFileFileError({ ...fileUploadErrors?.fileEmpty });
      setViewError(true);
      return;
    } else if (file && file.size > 5 * 1024 * 1024) {
      setFileFileError({ ...fileUploadErrors?.fileSize });
      setViewError(true);
      return;
    } else {
      setSelectedFile(file);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const type = file?.name?.split(".");
    const fileType = type[type.length - 1];
    fileCheck(file, fileType);
  };

  /**
   * The handleDrop function is used to handle file drops and select the dropped file if it meets certain
   * conditions.
   * @returns nothing (undefined) in both the cases where the file size is 0 or greater than 10 * 1024 *
   * 1024.
   */
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const type = file?.name?.split(".");
    const fileType = type[type.length - 1];
    fileCheck(file, fileType);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleCloseError = () => {
    setViewError(false);
    setFileFileError({});
  };

  return (
    <>
      <div className="Jdmain">
        <span className="resume"> Resume</span>
        <div>
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="dropBox"
          >
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".pdf"
            />
            <label htmlFor="fileInput" className="uploadIcon">
              <img src={UploadIcon} alt="" />
            </label>
            <div className="firstLabel">
              <label htmlFor="fileInput" className="label1">
                Drag and drop
              </label>
              <label htmlFor="fileInput" className="label2">
                {" "}
                or <span>Choose file</span> to upload
              </label>
            </div>
            <div className="secondLabel">Upload Pdf file of max 5 MB only</div>
          </div>
        </div>
        {selectedFile ? (
          <div className="selected">
            {" "}
            <div>{selectedFile.name}</div>
            <div onClick={() => setSelectedFile(null)}>
              <img src={crossFile} alt="" />{" "}
            </div>
          </div>
        ) : (
          <div className="selected">
            <div>{resumeName}</div>
          </div>
        )}
      </div>
      {viewError && (
        <FilesError setviewFileError={handleCloseError} fileError={fileError} />
      )}
    </>
  );
};

const CertificateUpload = ({
  selectedFiles,
  setSelectedFiles,
  certificatesName,
}) => {
  const [viewError, setViewError] = useState(false);
  const [fileError, setFileFileError] = useState({});

  const fileCheck = (files) => {
    let newSelectedFiles = selectedFiles.slice();
    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      const type = file?.name?.split(".");
      const fileType = type[type.length - 1];

      const allowedTypes = ["pdf", "png", "jpeg", "jpg"];
      if (!allowedTypes?.includes(fileType)) {
        // toast("file format not supported: " + file?.name);
        setFileFileError({ ...fileUploadErrors?.fileFormat });
        setViewError(true);
        return;
      }

      if (file.size === 0) {
        // toast("File is empty: " + file.name);
        setFileFileError({ ...fileUploadErrors?.fileEmpty });
        setViewError(true);
        return;
      } else if (file.size > 5 * 1024 * 1024) {
        // toast("File size exceeds the 10MB limit: " + file.name);
        setFileFileError({ ...fileUploadErrors?.fileSize });
        setViewError(true);
        return;
      } else if (newSelectedFiles?.length < 3) {
        newSelectedFiles.push(file);
      } else {
        // toast("You can select a maximum of 3 files.");
        setFileFileError({ ...fileUploadErrors?.fileCount });
        setViewError(true);
        return;
      }
    }

    setSelectedFiles(newSelectedFiles);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    fileCheck(files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    fileCheck(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (fileName) => {
    const newSelectedFiles = selectedFiles.filter(
      (file) => file.name !== fileName
    );
    setSelectedFiles(newSelectedFiles);
  };

  const handleCloseError = () => {
    setFileFileError({});
    setViewError(false);
  };

  useEffect(() => {
    // console.log("certi==>", certificatesName);
  }, []);

  return (
    <>
      <div className="Jdmain">
        <div className="resume">Certifications (Optional)</div>
        <div>
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="dropBox"
          >
            <input
              type="file"
              id="fileInputs"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".pdf,.png,.jpg,.jpeg"
              multiple
            />
            <label htmlFor="fileInputs" className="uploadIcon">
              <img src={UploadIcon} alt="" />
            </label>
            <div className="firstLabel">
              <label htmlFor="fileInputs" className="label1">
                Drag and drop
              </label>
              <label htmlFor="fileInputs" className="label2">
                {" "}
                or <span>Choose file</span> to upload
              </label>
            </div>
            <div className="secondLabel">
              Upload max of 3 PDF/Png/Jpeg/jpg file of max 5 MB only
            </div>
          </div>
        </div>
        {selectedFiles && selectedFiles?.length > 0 ? (
          <div className="selected">
            {selectedFiles.map((file, index) => (
              <div key={index} className="multiple">
                <div>{file.name}</div>
                <div onClick={() => handleRemoveFile(file.name)}>
                  <img src={crossFile} alt="" />{" "}
                </div>
              </div>
            ))}
          </div>
        ) : (
          certificatesName.length > 0 && (
            <div className="selected selected-certificates">
              {certificatesName.map((file, index) => (
                <div key={index} className="multiple">
                  <div>{file}</div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
      {viewError && (
        <FilesError setviewFileError={handleCloseError} fileError={fileError} />
      )}
    </>
  );
};

const checkFormData = (
  formData,
  setFormError,
  resume,
  certificates,
  setIsLinkedValid
) => {
  const requiredFields = [
    { key: "firstName", message: "First name cannot be empty" },
    { key: "lastName", message: "Last name cannot be empty" },
    { key: "mobile", message: "Contact number cannot be empty" },
    { key: "country", message: "Country cannot be empty" },
    { key: "pincode", message: "pincode cannot be empty" },
    { key: "state", message: "State cannot be empty" },
    { key: "city", message: "City cannot be empty" },
    {
      key: "education",
      message: "Highest Education Qualification cannot be empty",
    },
    { key: "qualification", message: "Qualification cannot be empty" },
    { key: "experienceInYears", message: "Experiences cannot be empty" },
    { key: "availabilityForWork", message: "Availability cannot be empty" },
    { key: "linkedInProfile", message: "Linkedin profile cannot be empty" },
    { key: "timeZone", message: "Time Zone cannot be empty" },
  ];

  let allGood = true;

  for (const { key, message } of requiredFields) {
    if (!formData[key]) {
      // toast(message);
      // console.log(key, "is missing");
      setFormError((prev) => ({
        ...prev,
        [key]: true,
      }));
      // return false;
      allGood = false;
    }
  }

  if (formData?.availabilityForWork === "Immediate" && !formData?.joiningDate) {
    // toast("Joining Date is required");
    setFormError((prev) => ({
      ...prev,
      ["joiningDate"]: true,
      ["lastWorkingDate"]: false,
      ["noticePeriod"]: false,
    }));
    // return false;
    allGood = false;
  }
  if (
    formData?.availabilityForWork === "Serving notice period" &&
    !formData?.lastWorkingDate
  ) {
    // toast("last working date is required");
    setFormError((prev) => ({
      ...prev,
      ["lastWorkingDate"]: true,
      ["joiningDate"]: false,
      ["noticePeriod"]: false,
    }));
    // return false;
    allGood = false;
  }
  if (
    formData?.availabilityForWork === "Currently working" &&
    !formData?.noticePeriod
  ) {
    // toast("Notice period is required");
    setFormError((prev) => ({
      ...prev,
      ["noticePeriod"]: true,
      ["lastWorkingDate"]: false,
      ["joiningDate"]: false,
    }));
    // return false;
    allGood = false;
  }

  // if (!resume) {
  //   toast("resume is required");
  //   return false;
  // }

  if (formData?.linkedInProfile) {
    const linkedinUrlPattern =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;

    if (linkedinUrlPattern.test(formData?.linkedInProfile)) {
      // Valid LinkedIn URL
      setIsLinkedValid(true);
    } else {
      // Invalid LinkedIn URL
      setIsLinkedValid(false);
      allGood = false;
    }
  }

  if (formData?.mobile && formData?.mobile?.length < 10) {
    setFormError((prev) => ({
      ...prev,
      ["mobile"]: true,
    }));
    allGood = false;
  }

  if (allGood) return true;
  else return false;
};
const generateFormData = (formData, resume, certificates) => {
  const data = {
    jsonData: { ...formData },
  };

  // Update the timeZone format
  if (data["jsonData"]["timeZone"]) {
    const timeZoneString = data["jsonData"]["timeZone"];
    const [timeZone, utcTimeDifference] = timeZoneString.split(" ");

    data["jsonData"]["timeZone"] = {
      timeZone: timeZone?.trim(), // "Asia/Kolkata"
      utcTimeDifference: `${utcTimeDifference?.trim()}`, // "GMT+5:30"
    };
  }

  // Set the id from localStorage
  data["jsonData"]["id"] = localStorage.getItem("email");
  data["jsonData"]["clientId"] = localStorage.getItem("clientId");
  // Handle availabilityForWork conditions
  if (data["jsonData"]["availabilityForWork"] === "Immediate") {
    data["jsonData"]["lastWorkingDate"] = null;
    data["jsonData"]["noticePeriod"] = null;
  } else if (
    data["jsonData"]["availabilityForWork"] === "Serving notice period"
  ) {
    data["jsonData"]["joiningDate"] = null;
    data["jsonData"]["noticePeriod"] = null;
  } else if (data["jsonData"]["availabilityForWork"] === "Currently working") {
    data["jsonData"]["joiningDate"] = null;
    data["jsonData"]["lastWorkingDate"] = null;
  }

  // Create a new FormData object
  const formDataObject = new FormData();

  // Append the JSON data
  formDataObject.append("jsonData", JSON.stringify(data.jsonData));

  // Append the resume field if it exists
  if (resume) {
    formDataObject.append("resume", resume);
  }

  // Append certificates individually
  if (certificates.length > 0) {
    certificates.forEach((certificate, index) => {
      formDataObject.append(`certificates`, certificate);
    });
  }

  return formDataObject;
};

// const FilesError = ({ setviewFileError, fileError }) => {
//   return (
//     <Popup>
//       <div className="FilesErrorMain">
//         <div className="close" onClick={() => setviewFileError()}>
//           <img src={close} alt="" />
//         </div>
//         <div className="errorSign">
//           <img src={fileError?.icon} alt="" />
//         </div>
//         <div className="title1">{fileError?.message1}</div>
//         <div className="title2">{fileError?.message2}</div>
//         <button className="okay" onClick={() => setviewFileError()}>
//           Okay
//         </button>
//       </div>
//     </Popup>
//   );
// };
